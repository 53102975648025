import React from 'react'

const Support = () => {

    return (

        <h1> This is the support Page </h1>

    )

}

export default Support